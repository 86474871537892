export const SCRIPS_MEPS_MESSAGES = {
    "camt.029.001.09": {
        "title": "camt.029.001.09",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\">\n" +
            "\t<Fr>\n" +
            "\t\t<FIId>\n" +
            "\t\t\t<FinInstnId>\n" +
            "\t\t\t\t<BICFI>MASGSGSCRTG</BICFI>\n" +
            "\t\t\t</FinInstnId>\n" +
            "\t\t</FIId>\n" +
            "\t</Fr>\n" +
            "\t<To>\n" +
            "\t\t<FIId>\n" +
            "\t\t\t<FinInstnId>\n" +
            "\t\t\t\t<BICFI>AAAASGSGXXX</BICFI>\n" +
            "\t\t\t</FinInstnId>\n" +
            "\t\t</FIId>\n" +
            "\t</To>\n" +
            "\t<BizMsgIdr>Isi-Outgoing-05192022-02</BizMsgIdr>\n" +
            "\t<MsgDefIdr>camt.029.001.09</MsgDefIdr>\n" +
            "\t<BizSvc>mas.scrips.02</BizSvc>\n" +
            "\t<CreDt>2022-05-19T18:25:00+08:00</CreDt>\n" +
            "</AppHdr>\n" +
            "\n" +
            "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.029.001.09\">\n" +
            "\t<RsltnOfInvstgtn>\n" +
            "\t\t<Assgnmt>\n" +
            "\t\t\t<Id>Id</Id>\n" +
            "\t\t\t<Assgnr>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<BICFI>AAAASGSGXXX</BICFI>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgnr>\n" +
            "\t\t\t<Assgne>\n" +
            "\t\t\t\t<Agt>\n" +
            "\t\t\t\t\t<FinInstnId>\n" +
            "\t\t\t\t\t\t<BICFI>MASGSGSCRTG</BICFI>\n" +
            "\t\t\t\t\t</FinInstnId>\n" +
            "\t\t\t\t</Agt>\n" +
            "\t\t\t</Assgne>\n" +
            "\t\t\t<CreDtTm>2022-05-20T17:57:00+08:00</CreDtTm>\n" +
            "\t\t</Assgnmt>\n" +
            "\t\t<Sts>\n" +
            "\t\t\t<Conf>CNCL</Conf>\n" +
            "\t\t</Sts>\n" +
            "\t\t<CxlDtls>\n" +
            "\t\t\t<TxInfAndSts>\n" +
            "\t\t\t\t<OrgnlGrpInf>\n" +
            "\t\t\t\t\t<OrgnlMsgId>Ii-Out-042922-ts001-tc009-t1</OrgnlMsgId>\n" +
            "\t\t\t\t\t<OrgnlMsgNmId>OrgnlMsgNmId</OrgnlMsgNmId>\n" +
            "\t\t\t\t</OrgnlGrpInf>\n" +
            "\t\t\t\t<OrgnlInstrId>Out-RTG-1-TPB-test1-05192022-02</OrgnlInstrId>\n" +
            "\t\t\t\t<OrgnlEndToEndId>EE-RTG-1-TPB-test1-05192022-02</OrgnlEndToEndId>\n" +
            "\t\t\t\t<OrgnlUETR>6dc644c1-e835-4dc6-a946-59f6bb52f24b</OrgnlUETR>\n" +
            "\t\t\t</TxInfAndSts>\n" +
            "\t\t</CxlDtls>\n" +
            "\t</RsltnOfInvstgtn>\n" +
            "</Document>"
    },
    "camt.053.001.08": {
        "title": "camt.053.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\">\n" +
            "    <Fr>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>MASGSGSCRTG</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </Fr>\n" +
            "    <To>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>AAAASGSGXXX</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </To>\n" +
            "    <BizMsgIdr>RM2022080108401957094687</BizMsgIdr>\n" +
            "    <MsgDefIdr>camt.053.001.08</MsgDefIdr>\n" +
            "    <BizSvc>mas.scrips.02</BizSvc>\n" +
            "    <CreDt>2022-08-01T08:40:19.571+08:00</CreDt>\n" +
            "</AppHdr>\n" +
            "\n" +
            "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.053.001.08\">\n" +
            "    <BkToCstmrStmt>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>RM202208010840195581613</MsgId>\n" +
            "            <CreDtTm>2022-08-01T08:40:19.561+08:00</CreDtTm>\n" +
            "        </GrpHdr>\n" +
            "        <Stmt>\n" +
            "            <Id>1</Id>\n" +
            "            <StmtPgntn>\n" +
            "                <PgNb>1</PgNb>\n" +
            "                <LastPgInd>false</LastPgInd>\n" +
            "            </StmtPgntn>\n" +
            "            <Acct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>31140201</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "                <Ccy>SGD</Ccy>\n" +
            "                <Nm>Participant2</Nm>\n" +
            "                <Ownr>\n" +
            "                    <Id>\n" +
            "                        <OrgId>\n" +
            "                            <AnyBIC>AAAASGSG</AnyBIC>\n" +
            "                        </OrgId>\n" +
            "                    </Id>\n" +
            "                </Ownr>\n" +
            "                <Svcr>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>MASGSGSCRTG</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </Svcr>\n" +
            "            </Acct>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>OPBD</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"SGD\">89710790.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>CLBD</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"SGD\">89710457.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>CLAV</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"SGD\">89710457.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <Ntry>\n" +
            "                <NtryRef>RT2022072915304953328045</NtryRef>\n" +
            "                <Amt Ccy=\"SGD\">333.00</Amt>\n" +
            "                <CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "                <Sts>\n" +
            "                    <Cd>BOOK</Cd>\n" +
            "                </Sts>\n" +
            "                <BookgDt>\n" +
            "                    <DtTm>2022-07-29T15:30:49.000+08:00</DtTm>\n" +
            "                </BookgDt>\n" +
            "                <ValDt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </ValDt>\n" +
            "                <BkTxCd>\n" +
            "                    <Prtry>\n" +
            "                        <Cd>PMNT</Cd>\n" +
            "                    </Prtry>\n" +
            "                </BkTxCd>\n" +
            "                <NtryDtls>\n" +
            "                    <TxDtls>\n" +
            "                        <Refs>\n" +
            "                            <InstrId>E-test1-07292022-33</InstrId>\n" +
            "                            <EndToEndId>RT2022072915304953328045</EndToEndId>\n" +
            "                        </Refs>\n" +
            "                        <Amt Ccy=\"SGD\">333.00</Amt>\n" +
            "                        <RltdPties>\n" +
            "                            <Dbtr>\n" +
            "                                <Pty>\n" +
            "                                    <Nm>Participant2</Nm>\n" +
            "                                    <Id>\n" +
            "                                        <OrgId>\n" +
            "                                            <AnyBIC>AAAASGSGXXX</AnyBIC>\n" +
            "                                        </OrgId>\n" +
            "                                    </Id>\n" +
            "                                </Pty>\n" +
            "                            </Dbtr>\n" +
            "                            <Cdtr>\n" +
            "                                <Pty>\n" +
            "                                    <Nm>Participant03</Nm>\n" +
            "                                    <Id>\n" +
            "                                        <OrgId>\n" +
            "                                            <AnyBIC>BBBBSGSGXXX</AnyBIC>\n" +
            "                                        </OrgId>\n" +
            "                                    </Id>\n" +
            "                                </Pty>\n" +
            "                            </Cdtr>\n" +
            "                        </RltdPties>\n" +
            "                        <RltdAgts>\n" +
            "                            <InstgAgt>\n" +
            "                                <FinInstnId>\n" +
            "                                    <BICFI>AAAASGSGXXX</BICFI>\n" +
            "                                </FinInstnId>\n" +
            "                            </InstgAgt>\n" +
            "                            <InstdAgt>\n" +
            "                                <FinInstnId>\n" +
            "                                    <BICFI>BBBBSGSGXXX</BICFI>\n" +
            "                                </FinInstnId>\n" +
            "                            </InstdAgt>\n" +
            "                        </RltdAgts>\n" +
            "                    </TxDtls>\n" +
            "                </NtryDtls>\n" +
            "            </Ntry>\n" +
            "        </Stmt>\n" +
            "    </BkToCstmrStmt>\n" +
            "</Document>"
    },
    "camt.053.001.08.aos": {
        "title": "camt.053.001.08 AOS",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\n" +
            "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\">\n" +
            "    <Fr>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>MASGSGSCRTG</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </Fr>\n" +
            "    <To>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>AAAASGSGXXX</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </To>\n" +
            "    <BizMsgIdr>RM2022080108401957094687</BizMsgIdr>\n" +
            "    <MsgDefIdr>camt.053.001.08</MsgDefIdr>\n" +
            "    <BizSvc>mas.scrips.aos.02</BizSvc>\n" +
            "    <CreDt>2022-08-01T08:40:19.571+08:00</CreDt>\n" +
            "</AppHdr>\n" +
            "\n" +
            "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.053.001.08\">\n" +
            "    <BkToCstmrStmt>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>RM202208010840195581613</MsgId>\n" +
            "            <CreDtTm>2022-08-01T08:40:19.561+08:00</CreDtTm>\n" +
            "        </GrpHdr>\n" +
            "        <Stmt>\n" +
            "            <Id>1</Id>\n" +
            "            <StmtPgntn>\n" +
            "                <PgNb>1</PgNb>\n" +
            "                <LastPgInd>false</LastPgInd>\n" +
            "            </StmtPgntn>\n" +
            "            <Acct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>31140201</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "                <Ccy>SGD</Ccy>\n" +
            "                <Nm>Participant2</Nm>\n" +
            "                <Ownr>\n" +
            "                    <Id>\n" +
            "                        <OrgId>\n" +
            "                            <AnyBIC>AAAASGSG</AnyBIC>\n" +
            "                        </OrgId>\n" +
            "                    </Id>\n" +
            "                </Ownr>\n" +
            "                <Svcr>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>MASGSGSCRTG</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </Svcr>\n" +
            "            </Acct>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>OPBD</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"SGD\">89710790.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>CLBD</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"SGD\">89710457.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <Bal>\n" +
            "                <Tp>\n" +
            "                    <CdOrPrtry>\n" +
            "                        <Cd>CLAV</Cd>\n" +
            "                    </CdOrPrtry>\n" +
            "                </Tp>\n" +
            "                <Amt Ccy=\"SGD\">89710457.00</Amt>\n" +
            "                <CdtDbtInd>CRDT</CdtDbtInd>\n" +
            "                <Dt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </Dt>\n" +
            "            </Bal>\n" +
            "            <Ntry>\n" +
            "                <NtryRef>RT2022072915304953328045</NtryRef>\n" +
            "                <Amt Ccy=\"SGD\">333.00</Amt>\n" +
            "                <CdtDbtInd>DBIT</CdtDbtInd>\n" +
            "                <Sts>\n" +
            "                    <Cd>BOOK</Cd>\n" +
            "                </Sts>\n" +
            "                <BookgDt>\n" +
            "                    <DtTm>2022-07-29T15:30:49.000+08:00</DtTm>\n" +
            "                </BookgDt>\n" +
            "                <ValDt>\n" +
            "                    <Dt>2022-07-29+08:00</Dt>\n" +
            "                </ValDt>\n" +
            "                <BkTxCd>\n" +
            "                    <Prtry>\n" +
            "                        <Cd>PMNT</Cd>\n" +
            "                    </Prtry>\n" +
            "                </BkTxCd>\n" +
            "                <NtryDtls>\n" +
            "                    <TxDtls>\n" +
            "                        <Refs>\n" +
            "                            <InstrId>E-test1-07292022-33</InstrId>\n" +
            "                            <EndToEndId>RT2022072915304953328045</EndToEndId>\n" +
            "                        </Refs>\n" +
            "                        <Amt Ccy=\"SGD\">333.00</Amt>\n" +
            "                        <RltdPties>\n" +
            "                            <Dbtr>\n" +
            "                                <Pty>\n" +
            "                                    <Nm>Participant2</Nm>\n" +
            "                                    <Id>\n" +
            "                                        <OrgId>\n" +
            "                                            <AnyBIC>AAAASGSGXXX</AnyBIC>\n" +
            "                                        </OrgId>\n" +
            "                                    </Id>\n" +
            "                                </Pty>\n" +
            "                            </Dbtr>\n" +
            "                            <Cdtr>\n" +
            "                                <Pty>\n" +
            "                                    <Nm>Participant03</Nm>\n" +
            "                                    <Id>\n" +
            "                                        <OrgId>\n" +
            "                                            <AnyBIC>BBBBSGSGXXX</AnyBIC>\n" +
            "                                        </OrgId>\n" +
            "                                    </Id>\n" +
            "                                </Pty>\n" +
            "                            </Cdtr>\n" +
            "                        </RltdPties>\n" +
            "                        <RltdAgts>\n" +
            "                            <InstgAgt>\n" +
            "                                <FinInstnId>\n" +
            "                                    <BICFI>AAAASGSGXXX</BICFI>\n" +
            "                                </FinInstnId>\n" +
            "                            </InstgAgt>\n" +
            "                            <InstdAgt>\n" +
            "                                <FinInstnId>\n" +
            "                                    <BICFI>BBBBSGSGXXX</BICFI>\n" +
            "                                </FinInstnId>\n" +
            "                            </InstdAgt>\n" +
            "                        </RltdAgts>\n" +
            "                    </TxDtls>\n" +
            "                </NtryDtls>\n" +
            "            </Ntry>\n" +
            "        </Stmt>\n" +
            "    </BkToCstmrStmt>\n" +
            "</Document>"
    },
    "camt.056.001.08": {
        "title": "camt.056.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\">\n" +
            "  <Fr>\n" +
            "    <FIId>\n" +
            "      <FinInstnId>\n" +
            "        <BICFI>AAAASGSGXXX</BICFI>\n" +
            "      </FinInstnId>\n" +
            "    </FIId>\n" +
            "  </Fr>\n" +
            "  <To>\n" +
            "    <FIId>\n" +
            "      <FinInstnId>\n" +
            "        <BICFI>MASGSGSCRTG</BICFI>\n" +
            "      </FinInstnId>\n" +
            "    </FIId>\n" +
            "  </To>\n" +
            "  <BizMsgIdr>MXRMJOE0</BizMsgIdr>\n" +
            "  <MsgDefIdr>camt.056.001.08</MsgDefIdr>\n" +
            "  <BizSvc>mas.scrips.02</BizSvc>\n" +
            "  <CreDt>2022-06-20T18:25:00+08:00</CreDt>\n" +
            "</AppHdr>\n" +
            "\n" +
            "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:camt.056.001.08\">\n" +
            "  <FIToFIPmtCxlReq>\n" +
            "    <Assgnmt>\n" +
            "      <Id>Id</Id>\n" +
            "      <Assgnr>\n" +
            "        <Agt>\n" +
            "          <FinInstnId>\n" +
            "            <BICFI>AAAASGSGXXX</BICFI>\n" +
            "          </FinInstnId>\n" +
            "        </Agt>\n" +
            "      </Assgnr>\n" +
            "      <Assgne>\n" +
            "        <Agt>\n" +
            "          <FinInstnId>\n" +
            "            <BICFI>MASGSGSCRTG</BICFI>\n" +
            "          </FinInstnId>\n" +
            "        </Agt>\n" +
            "      </Assgne>\n" +
            "      <CreDtTm>2022-06-20T17:57:00+08:00</CreDtTm>\n" +
            "    </Assgnmt>\n" +
            "    <Undrlyg>\n" +
            "      <TxInf>\n" +
            "        <OrgnlGrpInf>\n" +
            "          <OrgnlMsgId>GGSFGS-200622-11</OrgnlMsgId>\n" +
            "          <OrgnlMsgNmId>pacs.009.001.08</OrgnlMsgNmId>\n" +
            "          <OrgnlCreDtTm>2022-06-20T12:00:00+08:00</OrgnlCreDtTm>\n" +
            "        </OrgnlGrpInf>\n" +
            "        <OrgnlInstrId>Out2006202211</OrgnlInstrId>\n" +
            "        <OrgnlEndToEndId>EERTG1TPD2006202211</OrgnlEndToEndId>\n" +
            "        <OrgnlUETR>6dc644c1-e835-4dc6-a946-89f6ab79f06b</OrgnlUETR>\n" +
            "        <OrgnlIntrBkSttlmAmt Ccy=\"SGD\">1000</OrgnlIntrBkSttlmAmt>\n" +
            "        <OrgnlIntrBkSttlmDt>2022-06-23</OrgnlIntrBkSttlmDt>\n" +
            "        <CxlRsnInf>\n" +
            "          <Rsn>\n" +
            "            <Cd>DUPL</Cd>\n" +
            "          </Rsn>\n" +
            "        </CxlRsnInf>\n" +
            "      </TxInf>\n" +
            "    </Undrlyg>\n" +
            "  </FIToFIPmtCxlReq>\n" +
            "</Document>"
    },
    "pacs.008.001.08": {
        "title": "pacs.008.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\">\n" +
            "  <Fr>\n" +
            "    <FIId>\n" +
            "      <FinInstnId>\n" +
            "        <BICFI>AAAASGSGXXX</BICFI>\n" +
            "      </FinInstnId>\n" +
            "    </FIId>\n" +
            "  </Fr>\n" +
            "  <To>\n" +
            "    <FIId>\n" +
            "      <FinInstnId>\n" +
            "        <BICFI>BBBBSGSGXXX</BICFI>\n" +
            "      </FinInstnId>\n" +
            "    </FIId>\n" +
            "  </To>\n" +
            "  <BizMsgIdr>Outgoing-210622-102</BizMsgIdr>\n" +
            "  <MsgDefIdr>pacs.008.001.08</MsgDefIdr>\n" +
            "  <BizSvc>mas.scrips.02</BizSvc>\n" +
            "  <CreDt>2022-06-21T17:57:00+08:00</CreDt>\n" +
            "  <Prty>NORQ</Prty>\n" +
            "</AppHdr>\n" +
            "\n" +
            "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08\">\n" +
            "  <FIToFICstmrCdtTrf>\n" +
            "    <GrpHdr>\n" +
            "      <MsgId>210622102</MsgId>\n" +
            "      <CreDtTm>2022-06-21T17:22:00+08:00</CreDtTm>\n" +
            "      <NbOfTxs>1</NbOfTxs>\n" +
            "      <SttlmInf>\n" +
            "        <SttlmMtd>CLRG</SttlmMtd>\n" +
            "        <ClrSys>\n" +
            "          <Cd>SCR</Cd>\n" +
            "        </ClrSys>\n" +
            "      </SttlmInf>\n" +
            "    </GrpHdr>\n" +
            "    <CdtTrfTxInf>\n" +
            "      <PmtId>\n" +
            "        <InstrId>210622102</InstrId>\n" +
            "        <EndToEndId>21062022102</EndToEndId>\n" +
            "        <UETR>6dc644c1-e835-4dc6-a835-79f8bd17f05b</UETR>\n" +
            "      </PmtId>\n" +
            "      <PmtTpInf>\n" +
            "        <InstrPrty>NORM</InstrPrty>\n" +
            "        <SvcLvl>\n" +
            "          <Cd>012</Cd>\n" +
            "        </SvcLvl>\n" +
            "        <LclInstrm>\n" +
            "          <Prtry>CRED</Prtry>\n" +
            "        </LclInstrm>\n" +
            "      </PmtTpInf>\n" +
            "      <IntrBkSttlmAmt Ccy=\"SGD\">600.00</IntrBkSttlmAmt>\n" +
            "      <IntrBkSttlmDt>2022-06-21</IntrBkSttlmDt>\n" +
            "      <InstdAmt Ccy=\"SGD\">100000000</InstdAmt>\n" +
            "      <ChrgBr>SHAR</ChrgBr>\n" +
            "      <InstgAgt>\n" +
            "        <FinInstnId>\n" +
            "          <BICFI>AAAASGSGXXX</BICFI>\n" +
            "        </FinInstnId>\n" +
            "      </InstgAgt>\n" +
            "      <InstdAgt>\n" +
            "        <FinInstnId>\n" +
            "          <BICFI>BBBBSGSGXXX</BICFI>\n" +
            "        </FinInstnId>\n" +
            "      </InstdAgt>\n" +
            "      <Dbtr>\n" +
            "        <Nm>Bvalgari</Nm>\n" +
            "        <PstlAdr>\n" +
            "          <StrtNm>6TH OF SEPTEMBER BLVD. 152</StrtNm>\n" +
            "          <BldgNm>BUSINESS CENTER LEGIS</BldgNm>\n" +
            "          <PstCd>4000</PstCd>\n" +
            "          <TwnNm>BULGARIA</TwnNm>\n" +
            "          <Ctry>BG</Ctry>\n" +
            "        </PstlAdr>\n" +
            "        <Id>\n" +
            "          <OrgId>\n" +
            "            <AnyBIC>CCCCSGSG</AnyBIC>\n" +
            "            <LEI>213800PBMQ7ZZ7VG4J23</LEI>\n" +
            "          </OrgId>\n" +
            "        </Id>\n" +
            "      </Dbtr>\n" +
            "      <DbtrAcct>\n" +
            "        <Id>\n" +
            "          <Othr>\n" +
            "            <Id>91180201</Id>\n" +
            "          </Othr>\n" +
            "        </Id>\n" +
            "      </DbtrAcct>\n" +
            "      <DbtrAgt>\n" +
            "        <FinInstnId>\n" +
            "          <BICFI>AAAASGSGXXX</BICFI>\n" +
            "        </FinInstnId>\n" +
            "      </DbtrAgt>\n" +
            "      <CdtrAgt>\n" +
            "        <FinInstnId>\n" +
            "          <BICFI>BBBBSGSGXXX</BICFI>\n" +
            "        </FinInstnId>\n" +
            "      </CdtrAgt>\n" +
            "      <Cdtr>\n" +
            "        <Nm>Raffles</Nm>\n" +
            "        <PstlAdr>\n" +
            "          <StrtNm>18 QIXING ROAD XINCHANG</StrtNm>\n" +
            "          <BldgNm>FINANCIAL TOWER</BldgNm>\n" +
            "          <PstCd>312500</PstCd>\n" +
            "          <TwnNm>CHINA</TwnNm>\n" +
            "          <Ctry>CN</Ctry>\n" +
            "        </PstlAdr>\n" +
            "        <Id>\n" +
            "          <OrgId>\n" +
            "            <AnyBIC>DDDDSGSG</AnyBIC>\n" +
            "            <LEI>300300C1125133000036</LEI>\n" +
            "          </OrgId>\n" +
            "        </Id>\n" +
            "      </Cdtr>\n" +
            "      <CdtrAcct>\n" +
            "        <Id>\n" +
            "          <Othr>\n" +
            "            <Id>81170201</Id>\n" +
            "          </Othr>\n" +
            "        </Id>\n" +
            "      </CdtrAcct>\n" +
            "      <InstrForNxtAgt>\n" +
            "        <InstrInf>/REC/XXXYYY FFC072-3</InstrInf>\n" +
            "      </InstrForNxtAgt>\n" +
            "      <Purp>\n" +
            "        <Prtry>001</Prtry>\n" +
            "      </Purp>\n" +
            "      <RmtInf>\n" +
            "        <Ustrd>INV ABC12345-5</Ustrd>\n" +
            "      </RmtInf>\n" +
            "    </CdtTrfTxInf>\n" +
            "  </FIToFICstmrCdtTrf>\n" +
            "</Document>"
    },
    "pacs.009.001.08": {
        "title": "pacs.009.001.08",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\">\n" +
            "    <Fr>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>AAAASGSG</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </Fr>\n" +
            "    <To>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>BBBBSGSG</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </To>\n" +
            "    <BizMsgIdr>2022062213345437137547</BizMsgIdr>\n" +
            "    <MsgDefIdr>pacs.009.001.08</MsgDefIdr>\n" +
            "    <BizSvc>mas.scrips.02</BizSvc>\n" +
            "    <CreDt>2022-06-22T13:34:54.371+08:00</CreDt>\n" +
            "    <Prty>NORQ</Prty>\n" +
            "</AppHdr>\n" +
            "\n" +
            "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08\">\n" +
            "    <FICdtTrf>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>2022062213345435230447</MsgId>\n" +
            "            <CreDtTm>2022-06-22T13:34:54.357+08:00</CreDtTm>\n" +
            "            <NbOfTxs>1</NbOfTxs>\n" +
            "            <SttlmInf>\n" +
            "                <SttlmMtd>CLRG</SttlmMtd>\n" +
            "                <ClrSys>\n" +
            "                    <Cd>SCR</Cd>\n" +
            "                </ClrSys>\n" +
            "            </SttlmInf>\n" +
            "        </GrpHdr>\n" +
            "        <CdtTrfTxInf>\n" +
            "            <PmtId>\n" +
            "                <InstrId>202206221334479</InstrId>\n" +
            "                <EndToEndId>assaACSTS11</EndToEndId>\n" +
            "                <UETR>cca4a9ad-2f67-47ea-a1a7-62bf6d330d10</UETR>\n" +
            "            </PmtId>\n" +
            "            <PmtTpInf>\n" +
            "                <SvcLvl>\n" +
            "                    <Prtry>NORQ</Prtry>\n" +
            "                </SvcLvl>\n" +
            "                <LclInstrm>\n" +
            "                    <Cd>CRED</Cd>\n" +
            "                </LclInstrm>\n" +
            "            </PmtTpInf>\n" +
            "            <IntrBkSttlmAmt Ccy=\"SGD\">1000.00</IntrBkSttlmAmt>\n" +
            "            <IntrBkSttlmDt>2022-06-22</IntrBkSttlmDt>\n" +
            "            <InstgAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>AAAASGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstgAgt>\n" +
            "            <InstdAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>BBBBSGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstdAgt>\n" +
            "            <Dbtr>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>AAAASGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </Dbtr>\n" +
            "            <DbtrAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>AAAASGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </DbtrAgt>\n" +
            "            <CdtrAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>BBBBSGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </CdtrAgt>\n" +
            "            <Cdtr>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>BBBBSGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </Cdtr>\n" +
            "        </CdtTrfTxInf>\n" +
            "    </FICdtTrf>\n" +
            "</Document>"
    },
    "pacs.009.001.08.cov": {
        "title": "pacs.009.001.08 COV",
        "msg": "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<AppHdr xmlns=\"urn:iso:std:iso:20022:tech:xsd:head.001.001.02\">\n" +
            "    <Fr>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>AAAASGSGXXX</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </Fr>\n" +
            "    <To>\n" +
            "        <FIId>\n" +
            "            <FinInstnId>\n" +
            "                <BICFI>BBBBSGSGXXX</BICFI>\n" +
            "            </FinInstnId>\n" +
            "        </FIId>\n" +
            "    </To>\n" +
            "    <BizMsgIdr>S7HY65WI</BizMsgIdr>\n" +
            "    <MsgDefIdr>pacs.009.001.08</MsgDefIdr>\n" +
            "    <BizSvc>mas.scrips.cov.02</BizSvc>\n" +
            "    <CreDt>2022-05-13T01:24:33+08:00</CreDt>\n" +
            "    <Prty>NORQ</Prty>\n" +
            "</AppHdr>\n" +
            "\n" +
            "<?xml version=\"1.0\" encoding=\"UTF-8\" ?>\n" +
            "<Document xmlns=\"urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08\">\n" +
            "    <FICdtTrf>\n" +
            "        <GrpHdr>\n" +
            "            <MsgId>S7HY65WI</MsgId>\n" +
            "            <CreDtTm>2022-05-13T01:24:33+02:00</CreDtTm>\n" +
            "            <NbOfTxs>1</NbOfTxs>\n" +
            "            <SttlmInf>\n" +
            "                <SttlmMtd>CLRG</SttlmMtd>\n" +
            "                <ClrSys>\n" +
            "                    <Cd>SCR</Cd>\n" +
            "                </ClrSys>\n" +
            "            </SttlmInf>\n" +
            "        </GrpHdr>\n" +
            "        <CdtTrfTxInf>\n" +
            "            <PmtId>\n" +
            "                <InstrId>OutS7HY65WI</InstrId>\n" +
            "                <EndToEndId>EE-S7HY65WI</EndToEndId>\n" +
            "                <UETR>08834fe8-c8f5-47a5-a23e-91eb62870c56</UETR>\n" +
            "                <ClrSysRef>SGS439284ack</ClrSysRef>\n" +
            "            </PmtId>\n" +
            "            <PmtTpInf>\n" +
            "                <SvcLvl>\n" +
            "                    <Cd>G001</Cd>\n" +
            "                </SvcLvl>\n" +
            "            </PmtTpInf>\n" +
            "            <IntrBkSttlmAmt Ccy=\"SGD\">3872.01</IntrBkSttlmAmt>\n" +
            "            <IntrBkSttlmDt>2022-05-13</IntrBkSttlmDt>\n" +
            "            <PrvsInstgAgt1>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>CCCCSGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </PrvsInstgAgt1>\n" +
            "            <InstgAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>AAAASGSGXXX</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstgAgt>\n" +
            "            <InstdAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>BBBBSGSGXXX</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </InstdAgt>\n" +
            "            <Dbtr>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>AAAASGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </Dbtr>\n" +
            "            <DbtrAcct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>875593800</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "            </DbtrAcct>\n" +
            "            <CdtrAgt>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>BBBBSGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </CdtrAgt>\n" +
            "            <CdtrAgtAcct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>7775984</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "            </CdtrAgtAcct>\n" +
            "            <Cdtr>\n" +
            "                <FinInstnId>\n" +
            "                    <BICFI>DDDDSGSG</BICFI>\n" +
            "                </FinInstnId>\n" +
            "            </Cdtr>\n" +
            "            <CdtrAcct>\n" +
            "                <Id>\n" +
            "                    <Othr>\n" +
            "                        <Id>5550001</Id>\n" +
            "                    </Othr>\n" +
            "                </Id>\n" +
            "            </CdtrAcct>\n" +
            "            <InstrForNxtAgt>\n" +
            "                <InstrInf>/INS/BBBBSGS0</InstrInf>\n" +
            "            </InstrForNxtAgt>\n" +
            "            <InstrForNxtAgt>\n" +
            "                <InstrInf>/BNF/SGSILC</InstrInf>\n" +
            "            </InstrForNxtAgt>\n" +
            "            <InstrForNxtAgt>\n" +
            "                <InstrInf>//BREF ZYBWSGS0ILFDAY2ACTIVATE</InstrInf>\n" +
            "            </InstrForNxtAgt>\n" +
            "            <InstrForNxtAgt>\n" +
            "                <InstrInf>//SREF MASGSGS0ILFDAY2ACTIVATE</InstrInf>\n" +
            "            </InstrForNxtAgt>\n" +
            "            <UndrlygCstmrCdtTrf>\n" +
            "                <Dbtr>\n" +
            "                    <Nm>AAA Co LTD</Nm>\n" +
            "                    <PstlAdr>\n" +
            "                        <AdrLine>100 BANG ST</AdrLine>\n" +
            "                        <AdrLine>EASTWOOD</AdrLine>\n" +
            "                    </PstlAdr>\n" +
            "                    <Id>\n" +
            "                        <OrgId>\n" +
            "                            <AnyBIC>AAAJBG21XXX</AnyBIC>\n" +
            "                        </OrgId>\n" +
            "                    </Id>\n" +
            "                </Dbtr>\n" +
            "                <DbtrAcct>\n" +
            "                    <Id>\n" +
            "                        <Othr>\n" +
            "                            <Id>9991234001</Id>\n" +
            "                        </Othr>\n" +
            "                    </Id>\n" +
            "                </DbtrAcct>\n" +
            "                <DbtrAgt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>ABABSGSG</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </DbtrAgt>\n" +
            "                <DbtrAgtAcct>\n" +
            "                    <Id>\n" +
            "                        <Othr>\n" +
            "                            <Id>333043001</Id>\n" +
            "                        </Othr>\n" +
            "                    </Id>\n" +
            "                </DbtrAgtAcct>\n" +
            "                <PrvsInstgAgt1>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>AABBSGSG</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </PrvsInstgAgt1>\n" +
            "                <IntrmyAgt1>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>EEEESGSG</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </IntrmyAgt1>\n" +
            "                <IntrmyAgt1Acct>\n" +
            "                    <Id>\n" +
            "                        <Othr>\n" +
            "                            <Id>8880001</Id>\n" +
            "                        </Othr>\n" +
            "                    </Id>\n" +
            "                </IntrmyAgt1Acct>\n" +
            "                <CdtrAgt>\n" +
            "                    <FinInstnId>\n" +
            "                        <BICFI>FFFFSGSG</BICFI>\n" +
            "                    </FinInstnId>\n" +
            "                </CdtrAgt>\n" +
            "                <CdtrAgtAcct>\n" +
            "                    <Id>\n" +
            "                        <Othr>\n" +
            "                            <Id>111054039</Id>\n" +
            "                        </Othr>\n" +
            "                    </Id>\n" +
            "                </CdtrAgtAcct>\n" +
            "                <Cdtr>\n" +
            "                    <Nm>BBB CO LTD</Nm>\n" +
            "                    <PstlAdr>\n" +
            "                        <AdrLine>1000 BANKS ST</AdrLine>\n" +
            "                        <AdrLine>CENTRAL</AdrLine>\n" +
            "                    </PstlAdr>\n" +
            "                </Cdtr>\n" +
            "                <CdtrAcct>\n" +
            "                    <Id>\n" +
            "                        <Othr>\n" +
            "                            <Id>888101001</Id>\n" +
            "                        </Othr>\n" +
            "                    </Id>\n" +
            "                </CdtrAcct>\n" +
            "                <InstrForNxtAgt>\n" +
            "                    <InstrInf> /REC/AGD CODE FFC072</InstrInf>\n" +
            "                </InstrForNxtAgt>\n" +
            "                <RmtInf>\n" +
            "                    <Ustrd>TRANSFER FOR INV 0001</Ustrd>\n" +
            "                </RmtInf>\n" +
            "                <InstdAmt Ccy=\"SGD\">8964</InstdAmt>\n" +
            "            </UndrlygCstmrCdtTrf>\n" +
            "        </CdtTrfTxInf>\n" +
            "    </FICdtTrf>\n" +
            "</Document>  \n"
    }
}
